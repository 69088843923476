import { css } from "aphrodite";
import PropTypes from "prop-types";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import OnResize from "components/Common/OnResize";
import RequestContext from "pages/RequestContext";

import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  outer: {
    width: "100%",
    position: "relative",
    paddingBottom: 8,
  },
  outerOpen: {
    paddingBottom: 12,
  },
  container: {
    overflow: "hidden",
    width: "100%",
  },
  containerAnimatable: {
    // TODO: Reenable this when we can stop it from transitioning on load...  transition: generateTransition({ targets: ["height"], speed: "500ms" }),
  },
  boxShadowsOverflowContainer: {
    position: "relative",
    left: "-2rem",
    width: "calc(100% + 4rem)",
  },
  boxShadowsOverflowChildren: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  containerInnerHeightUpdate: {
    transition: "all 0s",
  },
  inner: {
    position: "relative",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    maxWidth: "100%",
    width: "100%",
  },
  hasOverflowContent: {
    cursor: "pointer",
  },
  children: {
    display: "inline",
    flex: 1,
    pointerEvents: "auto",

    overflowWrap: "break-word",
  },
  mobileOverlayContainer: {
    [ScreenSizes.smAndAbove]: {
      display: "block",
    },
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    cursor: "pointer",
  },
  overlayToggleContainer: {
    [ScreenSizes.mdAndAbove]: {
      justifyContent: "center",
      position: "absolute",
      top: 0,
      height: "100%",
      right: 0,
      width: "100%",
      pointerEvents: "none",
    },
  },
  toggle: {
    ...gStyles.fontSemiBold,
    fontSize: ".75rem",
    cursor: "pointer",
    opacity: 0,
    pointerEvents: "none",
    transition: "opacity 0.5s",
    zIndex: -1,
  },
  showToggle: {
    opacity: 1,
    zIndex: 0,
  },
  showToggleContainer: {
    opacity: 1,
    zIndex: 0,
  },
  hideToggleContainer: {
    opacity: 0,
    zIndex: 0,
  },
  overlayToggle: {
    [ScreenSizes.mdAndAbove]: {
      backgroundColor: "#fff",
      boxShadow: "0 0.1em 0.2em rgba(0,0,0,0.05)",
      border: "1px #eee solid",
      color: "#444",
      borderRadius: 8,
      padding: "0.4em 1em 0.4em",
    },
  },
  overlay: {
    [ScreenSizes.mdAndAbove]: {
      background:
        "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
      position: "absolute",
      top: "25%",
      left: 0,
      right: 0,
      bottom: 0,
      pointerEvents: "none",
    },
  },
  smallScreenAlwaysShowToggle: {
    [ScreenSizes.smAndBelow]: {
      opacity: 1,
      zIndex: 0,
    },
  },
};

const ShowMoreContainer = (props) => {
  const {
    externalToggle,
    checkForContentChanges,
    contentCheckInterval,
    shrink,
    toggleHeight,
    onShowMoreClick,
    hideOverlay,
    boxShadowsOverflow,
    disable,
    children,
    dangerouslySetHTML,
    disableToggleOnContentClick,
    autoOnServer,
    getToggleLabel,
    hideToggle,
    blockKeyPressToggle,
    getToggleHeightStyles,
  } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [innerHeight, setInnerHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  const innerRef = useRef(null);
  const innerOuterRef = useRef(null);
  const innerHeightCheck = useRef(null);
  const isInnerHeightUpdate = useRef(false);
  const isAnimatable = useRef(false);
  const isOpen = useMemo(
    () => (props.open !== null ? props.open : open),
    [open, props.open]
  );
  const requestContext = useContext(RequestContext);
  const calcInnerSize = useCallback(() => {
    let height = innerRef.current
      ? innerRef.current.clientHeight || innerHeight
      : toggleHeight;

    if (
      shrink &&
      innerRef.current &&
      innerRef.current.clientHeight <= innerOuterRef.current.clientHeight
    ) {
      height = innerRef.current.clientHeight;
    }

    if (isOpen && innerRef.current) {
      return height || contentHeight;
    }

    if (requestContext.server) {
      return toggleHeight;
    }

    return shrink && height < toggleHeight ? height : toggleHeight;
  }, [
    isOpen,
    requestContext.server,
    shrink,
    toggleHeight,
    innerHeight,
    contentHeight,
  ]);

  const innerSize = calcInnerSize();

  const handleContentResize = useCallback(
    ({ height }) => setContentHeight(height),
    []
  );
  const handleInnerResize = useCallback(
    ({ height }) => setInnerHeight(height),
    []
  );
  const handleResize = useCallback(
    (size) => {
      handleContentResize(size);
      handleInnerResize(size);
    },
    [handleContentResize, handleInnerResize]
  );

  const hasOverflowContent =
    (!innerRef.current && mobile) ||
    (innerRef.current && innerRef.current.clientHeight > toggleHeight);

  const handleContentCheck = useCallback(() => {
    const shouldResize =
      innerRef.current &&
      !requestContext.server &&
      innerHeight !== innerOuterRef.current.clientHeight;

    if (shouldResize) {
      isAnimatable.current = true;
      isInnerHeightUpdate.current = true;
      setInnerHeight(innerOuterRef.current.clientHeight);
    }
  }, [requestContext.server, innerHeight]);

  const handleToggle = useCallback(
    (e) => {
      e.preventDefault();

      if (blockKeyPressToggle && e && (e.which || e.keyCode)) {
        return null;
      }

      if (hasOverflowContent) {
        setOpen((prevOpen) => {
          const newOpen = !(open !== null ? open : prevOpen);

          if (onShowMoreClick) {
            onShowMoreClick(e, newOpen);
          }

          return newOpen;
        });
      }

      return null;
    },
    [hasOverflowContent, onShowMoreClick, open, blockKeyPressToggle]
  );

  const handleHover = useCallback(() => setHover(true), []);
  const handleHoverExit = useCallback(() => setHover(false), []);

  useEffect(() => {
    innerHeightCheck.current = setInterval(
      handleContentCheck,
      checkForContentChanges ? contentCheckInterval : 1000
    );

    return () => {
      if (innerHeightCheck.current) {
        clearInterval(innerHeightCheck.current);
      }
    };
  }, [checkForContentChanges, contentCheckInterval, handleContentCheck]);

  useEffect(() => {
    if (innerOuterRef.current) {
      setInnerHeight(innerOuterRef.current.clientHeight);
    }
  }, [innerOuterRef]);

  const { styles } = useStyles(
    [
      baseStyles,
      externalToggle && {
        outer: { [ScreenSizes.lgAndAbove]: { marginBottom: 0 } },
      },
      getToggleHeightStyles
        ? getToggleHeightStyles(innerSize)
        : {
            openStyle: {
              height: innerSize,
            },
          },
    ],
    props
  );

  if (disable) {
    return (
      <div
        className={css(
          styles.children,
          boxShadowsOverflow && styles.boxShadowsOverflowChildren
        )}
      >
        {children}
      </div>
    );
  }

  const renderToggle = () => (
    <div
      data-id="show-more-container-toggle"
      data-testid="show-more-container-toggle"
      className={css(
        styles.toggleContainer,
        !hideOverlay && styles.overlayToggleContainer
      )}
      {...(hasOverflowContent
        ? {
            onClick: handleToggle,
            onKeyDown: (e) => eventIsFieldTrigger(e) && handleToggle(e),
          }
        : {})}
    >
      <div
        style={{
          top: isOpen ? innerSize + 5 : innerSize - 12,
        }}
        className={css(
          styles.hideToggleContainer,
          hasOverflowContent && styles.showToggleContainer
        )}
      >
        {!hideToggle && (
          <span
            className={css(
              styles.toggle,
              !hideOverlay && styles.overlayToggle,
              (hideOverlay || (!hideOverlay && hover)) && styles.showToggle,
              styles.smallScreenAlwaysShowToggle
            )}
          >
            {getToggleLabel
              ? getToggleLabel(isOpen)
              : `Show ${isOpen ? "Less" : "More"}`}
          </span>
        )}
      </div>
    </div>
  );

  const renderOverlay = () => (
    <div
      data-id="show-more-container-overlay"
      className={css(styles.overlay)}
      onClick={handleToggle}
      onKeyDown={(e) => eventIsFieldTrigger(e) && handleToggle(e)}
    />
  );

  const renderDescription = () => {
    const handleDescriptonToggle = externalToggle ? () => {} : handleToggle;
    return dangerouslySetHTML ? (
      <div
        className={css(
          styles.children,
          boxShadowsOverflow && styles.boxShadowsOverflowChildren
        )}
        onClick={disableToggleOnContentClick ? null : handleDescriptonToggle}
        onKeyDown={(e) =>
          eventIsFieldTrigger(e) &&
          (disableToggleOnContentClick ? null : handleDescriptonToggle(e))
        }
        dangerouslySetInnerHTML={{ __html: dangerouslySetHTML }}
      />
    ) : (
      <div
        className={css(
          styles.children,
          boxShadowsOverflow && styles.boxShadowsOverflowChildren
        )}
        onClick={disableToggleOnContentClick ? null : handleDescriptonToggle}
        onKeyDown={(e) =>
          eventIsFieldTrigger(e) &&
          (disableToggleOnContentClick ? null : handleDescriptonToggle(e))
        }
      >
        {children}
      </div>
    );
  };

  const innerHeightUpdate = isInnerHeightUpdate.current;

  isInnerHeightUpdate.current = false;

  if (
    (autoOnServer && requestContext.server) ||
    (autoOnServer && !innerRef.current) ||
    toggleHeight === "auto"
  ) {
    return (
      <div className={css(styles.outer, styles.outerOpen)} ref={innerOuterRef}>
        <OnResize onResize={handleResize}>{children}</OnResize>
      </div>
    );
  }

  return (
    <div
      data-id="show-more-container"
      className={css(styles.outer, isOpen && styles.outerOpen)}
    >
      <div
        className={css(
          styles.container,
          styles.openStyle,
          isAnimatable && styles.containerAnimatable,
          innerHeightUpdate && styles.containerInnerHeightUpdate,
          boxShadowsOverflow && styles.boxShadowsOverflowContainer
        )}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverExit}
      >
        <div
          ref={innerOuterRef}
          className={css(styles.inner, styles.openStyle)}
        >
          <OnResize onResize={handleInnerResize}>
            <div
              ref={innerRef}
              className={css(
                styles.content,
                hasOverflowContent ? styles.hasOverflowContent : null
              )}
            >
              <OnResize onResize={handleContentResize}>
                {renderDescription()}
              </OnResize>
            </div>
          </OnResize>
        </div>
      </div>
      {!externalToggle &&
        !hideOverlay &&
        hasOverflowContent &&
        !isOpen &&
        renderOverlay()}
      {(!externalToggle || onShowMoreClick) && renderToggle()}
    </div>
  );
};

ShowMoreContainer.propTypes = {
  children: PropTypes.node,
  toggleHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideOverlay: PropTypes.bool,
  open: PropTypes.bool,
  externalToggle: PropTypes.bool,
  checkForContentChanges: PropTypes.bool,
  shrink: PropTypes.bool,
  contentCheckInterval: PropTypes.number,
  boxShadowsOverflow: PropTypes.bool,
  dangerouslySetHTML: PropTypes.string,
  disableToggleOnContentClick: PropTypes.bool,
  onShowMoreClick: PropTypes.func,
  disable: PropTypes.bool,
  autoOnServer: PropTypes.bool,
  getToggleLabel: PropTypes.func,
  hideToggle: PropTypes.bool,
  blockKeyPressToggle: PropTypes.bool,
  getToggleHeightStyles: PropTypes.func,
};

ShowMoreContainer.defaultProps = {
  children: null,
  toggleHeight: 40,
  hideOverlay: false,
  open: null,
  externalToggle: false,
  checkForContentChanges: false,
  shrink: false,
  contentCheckInterval: 200,
  boxShadowsOverflow: false,
  dangerouslySetHTML: null,
  disableToggleOnContentClick: false,
  onShowMoreClick: null,
  disable: false,
  autoOnServer: false,
  getToggleLabel: null,
  hideToggle: false,
  blockKeyPressToggle: false,
};

export default ShowMoreContainer;
