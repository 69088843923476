import getEncodedEntityID from "./getEncodedEntityID";

function getUserListLinkID(list) {
  if (!list) {
    return null;
  }
  if (list.get("privacy") === "link_only") {
    return list.get("link_only_id") || getEncodedEntityID(list.get("id"));
  }

  return getEncodedEntityID(list.get("id"));
}

export default getUserListLinkID;
