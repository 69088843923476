export const VOICE_ACTOR_CODE = "voiceActor";
export const GUEST_CODE = "guest";
export const GUEST_HOST_CODE = "guestHost";
export const HOST_CODE = "host";

const ROLES = [
  {
    title: "Host",
    code: HOST_CODE,
    preposition: "of",
  },
  {
    title: "Producer",
    code: "producer",
    preposition: "of",
  },
  {
    title: "Editor",
    code: "editor",
    preposition: "of",
  },
  {
    title: "Guest",
    code: GUEST_CODE,
    preposition: "on",
  },
  {
    title: "Voice Actor",
    code: VOICE_ACTOR_CODE,
    preposition: "on",
  },
  {
    title: "Other",
    code: "other",
    preposition: "on",
  },
];

export const getRole = (role) => {
  if (!role) {
    return null;
  }

  return ROLES.find((r) => r.code === role);
};

export function getVoiceActorCharacter(role) {
  if (!role.get("character")) {
    return VOICE_ACTOR_CODE;
  }

  return role.getIn(["character", "name"]);
}
