import { base62Decode } from "utils/encode";
import { truncateString } from "utils/truncate";

export const DEFAULT_DESCRIPTION = "Creator Profile";

export const getValueForGroup = (group, path) => {
  switch (group.get("field")) {
    case "role":
      return path.getIn(["role", "code"]);
    case "podcast_id":
    case "confirmed":
      return path.get(group.get("field"));
    default:
      return null;
  }
};

export function getCreditEpisodesKey(creator_id, groupPath, groups) {
  return `groupedCredits/${creator_id}/${groupPath
    .map(
      (path, i) =>
        `${groups.getIn([i, "field"])}:${getValueForGroup(groups.get(i), path)}`
    )
    .join("-")}`;
}

export function getPronoun(pronounType, contraction = false, subject = false) {
  switch (pronounType) {
    case "he_him": {
      if (subject) {
        return contraction ? "his" : "he";
      }

      return contraction ? "his" : "him";
    }
    case "she_her": {
      if (subject) {
        return contraction ? "hers" : "she";
      }

      return "her";
    }
    default: {
      if (subject) {
        return contraction ? "their" : "they";
      }

      return contraction ? "theirs" : "them";
    }
  }
}

export function getObjectivePronoun(creator) {
  return getPronoun(creator.get("pronouns"), false, false);
}

export const getFirstName = (creator) => {
  if (creator) {
    const firstName = (
      typeof creator === "string" ? creator : creator.get("name")
    )
      .trim()
      .split(" ")[0];

    return (firstName || "").trim();
  }

  return "";
};

export function getCreatorMetaDescription(creator) {
  return truncateString(
    `${creator.get("subtitle_long")}${creator.get("bio") ? ". " : ""}${
      creator.get("bio") ? creator.get("bio") : ""
    }`,
    400
  );
}

export const getCreatorMetaImageUrl = (creator) =>
  `https://imagegen.podchaser.com/creator/${creator.get("id")}.png`;

export const getCreatorIdFromParam = (param) => {
  try {
    const ID = parseInt(base62Decode(param.match(/([^-]+)(\?.*)?$/)[1]), 10);
    return ID;
  } catch (e) {
    return null;
  }
};
