import { VOICE_ACTOR_CODE } from "utils/roles";

export const LOAD_SPECIFIC_CREATOR_REQUEST = "LOAD_SPECIFIC_CREATOR_REQUEST";
export const LOAD_SPECIFIC_CREATOR_SUCCESS = "LOAD_SPECIFIC_CREATOR_SUCCESS";
export const LOAD_SPECIFIC_CREATOR_FAILURE = "LOAD_SPECIFIC_CREATOR_FAILURE";

export const LOAD_PODCASTS_FOR_CREATOR_REQUEST =
  "LOAD_PODCASTS_FOR_CREATOR_REQUEST";
export const LOAD_PODCASTS_FOR_CREATOR_SUCCESS =
  "LOAD_PODCASTS_FOR_CREATOR_SUCCESS";
export const LOAD_PODCASTS_FOR_CREATOR_FAILURE =
  "LOAD_PODCASTS_FOR_CREATOR_FAILURE";
export const RESET_NEW_CREATOR = "RESET_NEW_CREATOR";
export const ADD_TEMP_CREDIT_SUBMISSIONS = "ADD_TEMP_CREDIT_SUBMISSIONS";
export const REMOVE_TEMP_CREDIT_SUBMISSIONS = "REMOVE_TEMP_CREDIT_SUBMISSIONS";
export const DELETE_CREDIT_SUBMISSIONS = "DELETE_CREDIT_SUBMISSIONS";

export const VOICE_TALENT_ROLES = [VOICE_ACTOR_CODE, "voice_talent"];
