import {
  loadSpecificCreator,
  loadCreatorRoles,
  loadListOfCurrentCreditsOfPodcast,
  loadListOfRecurringCredits,
  submitCredit,
} from "routines/creator";

import * as constants from "constants/creator";

export default {
  loadSpecificCreator: (creator_id) => ({
    type: loadSpecificCreator.TRIGGER,
    payload: { creator_id },
  }),
  resetNewCreator: () => ({
    type: constants.RESET_NEW_CREATOR,
  }),
  addTempCreditSubmissions: (credits) => ({
    type: constants.ADD_TEMP_CREDIT_SUBMISSIONS,
    payload: {
      credits,
    },
  }),
  removeTempCreditSubmissions: (credits) => ({
    type: constants.REMOVE_TEMP_CREDIT_SUBMISSIONS,
    payload: {
      credits,
    },
  }),
  loadCreatorRoles: () => ({
    type: loadCreatorRoles.TRIGGER,
  }),
  loadListOfCurrentCreditsOfPodcast: (creatorId, podcastId) => ({
    type: loadListOfCurrentCreditsOfPodcast.TRIGGER,
    payload: {
      podcastId,
      creatorId,
    },
  }),
  loadListOfRecurringCredits: (creatorId) => ({
    type: loadListOfRecurringCredits.TRIGGER,
    payload: {
      creatorId,
    },
  }),
  submitCredits: ({ credits, podcastId, creatorId, showSubmissionPage }) => ({
    type: submitCredit.TRIGGER,
    payload: {
      credits,
      podcastId,
      creatorId,
      showSubmissionPage,
    },
  }),
  deleteCreditSubmissions: ({ creditsId }) => ({
    type: constants.DELETE_CREDIT_SUBMISSIONS,
    payload: {
      creditsId,
    },
  }),
};
