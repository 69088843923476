import { List, Map } from "immutable";
import { createSelector } from "reselect";

import { isLinkOnlyId } from "../utils/entity/userlist";

export const selectLists = (state) =>
  state.userlist ? state.userlist.getIn(["lists", "entities"]) : Map();

export const selectSpecificList = (state, id) =>
  isLinkOnlyId(id)
    ? state.userlist.getIn(["lists", "entitiesByLinkOnlyID", id?.toString()])
    : state.userlist.getIn(["lists", "entities", id?.toString()]);

export const selectUserLists = (state) =>
  state.userlist ? state.userlist.getIn(["lists", "entities"]) : Map();

export const selectUserListsByLinkOnlyId = (state) =>
  state.userlist
    ? state.userlist.getIn(["lists", "entitiesByLinkOnlyID"])
    : Map();

const selectSpecificUserListMemoized = createSelector(
  [selectUserLists, selectUserListsByLinkOnlyId, (_, podcastId) => podcastId],
  (userLists, userListsByLinkOnlyID, userListId) => {
    if (isLinkOnlyId(userListId)) {
      return userListsByLinkOnlyID.get(userListId?.toString());
    }
    return userLists.get(userListId?.toString());
  }
);
export const makeSelectSpecificUserList = () => selectSpecificUserListMemoized;

export const selectListPrivate = (state, id) =>
  state.userlist.getIn(["lists", "private", id?.toString()]);
export const selectListFailed = (state, id) =>
  state.userlist.getIn(["lists", "failed", id?.toString()]);
export const selectListLoading = (state, id) =>
  state.userlist.getIn(["lists", "loading", id?.toString()]);
export const selectListSaving = (state, id) =>
  state.userlist.getIn(["lists", "saving", id?.toString()]);

export const selectItemAtIndex = (state, id, index) =>
  state.userlist.getIn([
    "lists",
    "entities",
    id?.toString(),
    "items",
    index?.toString(),
  ]);
export const selectProfileUserLists = (state) =>
  state.user.getIn(["logged_in", "lists", "entities"], List());
export const selectProfileUserListsLoading = (state) =>
  state.user.getIn(["logged_in", "lists", "loading"]);
export const selectProfileUserListsLoaded = (state) =>
  state.user.getIn(["logged_in", "lists", "loaded"]);
export const selectProfileListSummaryEntityType = (state, id) =>
  state.user.getIn([
    "logged_in",
    "lists",
    "summaries",
    "by_type",
    id?.toString(),
  ]);

const selectProfileListSummariesByEntityType = (state, entity_type) =>
  state.user.getIn(["logged_in", "lists", "summaries", entity_type], Map());

const selectProfileListSummariesWithNoEntityType = (state) =>
  state.user.getIn(["logged_in", "lists", "summaries", "not_set"], Map());

export const selectProfileListSummaries = createSelector(
  selectProfileListSummariesByEntityType,
  selectProfileListSummariesWithNoEntityType,
  (withEntity, withoutEntity) => withEntity.merge(withoutEntity).toList()
);

export const selectLoggedInUserHasLikedList = createSelector(
  selectSpecificList,
  (list) => list && list.getIn(["user_data", "is_liked"])
);
export const selectListEntityType = createSelector(
  selectSpecificList,
  selectProfileListSummaryEntityType,
  (list, summaryType) => (list && list.get("entity_type")) || summaryType
);

export const selectListPodcastContactCount = (state, podcastId) =>
  state.userlist.getIn(["contactCounts", podcastId?.toString()]);
export const selectListContactCounts = (state, listId) =>
  state.userlist.getIn(["contactCountsByList", listId?.toString()]);
export const selectListContactCountsLoaded = (state, listId) =>
  state.userlist.getIn(["contactCountsLoaded", listId?.toString()]);
export const selectListContactCountsLoading = (state, listId) =>
  state.userlist.getIn(["contactCountsLoading", listId?.toString()]);

export const selectUserlistInsights = (state) =>
  state.userlist
    ? state?.userlist?.getIn(["userlistInsights", "entities"])
    : Map();

const selectSpecificUserlistInsights = createSelector(
  [selectUserlistInsights, (_, listId) => listId],
  (userlists, listId) => userlists?.get(listId?.toString())
);
export const makeSelectSpecificUserlistInsightsMemoized = () =>
  selectSpecificUserlistInsights;

export const selectUserlistInsightLoading = (state, listId) =>
  state?.userlist?.getIn(["userlistInsights", "loading", listId?.toString()]);
