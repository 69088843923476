import baseX from "base-x";

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

const base62 = baseX(BASE62);

export function base62Encode(str) {
  return base62.encode(
    Uint8Array.from(Array.from(str).map((letter) => letter.charCodeAt(0)))
  );
}

export function base62Decode(str) {
  return base62
    .decode(str.toString())
    .map((char) => String.fromCharCode(char))
    .join("");
}
