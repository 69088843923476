import { base62Encode } from "utils/encode";

const getEncodedEntityID = (id) => {
  if (!id || !typeof str === "number") {
    return;
  }

  return base62Encode(id.toString().padStart(7, "0"));
};

export default getEncodedEntityID;
